<template>
  <div id="app">
    <div class="container">
      <h1>记忆轨迹</h1>
      <p>“学习的过程是将点连接成线，将线连接成面，将面连接成体的过程。”</p>

      <div class="tabs">
        <button
          v-for="(category, index) in categories"
          :key="index"
          :class="{ active: currentCategory === category }"
          @click="currentCategory = category"
        >
          {{ category }}
        </button>
      </div>

      <div class="sticky-notes">
        <div
          class="note"
          v-for="(point, index) in filteredTechPoints"
          :key="index"
        >
          <h3>{{ point.title }}</h3>
          <p>{{ point.description }}</p>
          <div class="code-container">
            <pre><code class="language-bash">{{ point.code }}</code></pre>
            <button class="copy-code-btn" @click="copyToClipboard(point.code)">复制代码</button>
          </div>
        </div>
      </div>
    </div>
    
    <footer>
      <a class="beian-link" rel="license" href="https://beian.miit.gov.cn/" target="_blank" title="ICP">
          <span class="badge-subject">ICP</span>
          <span class="badge-value" style="background-color: #fc8c23;">粤ICP备2021125857号-3</span>
        </a> 
    </footer>

  </div>

</template>

<script>
import { ref, computed } from 'vue'; // 导入 ref 和 computed
import { useToast } from "vue-toastification";

export default {
  name: 'App',
  setup() {
    const toast = useToast();
    const categories = ['Linux', 'Nginx', 'Python'];
    const techPoints = [
      // Linux 用例
      {
        title: 'Linux 文件权限',
        description: '使用 chmod 命令设置文件权限。',
        code: 'chmod 755 filename',
        category: 'Linux'
      },
      {
        title: 'Linux 进程管理',
        description: '使用 ps 和 top 命令监控系统进程。',
        code: 'ps aux | grep process_name',
        category: 'Linux'
      },
      {
        title: 'Linux 网络配置',
        description: '使用 ifconfig 命令查看网络配置。',
        code: 'ifconfig',
        category: 'Linux'
      },
      {
        title: 'Linux 软件包管理',
        description: '使用 apt-get 安装软件包。',
        code: 'sudo apt-get install package_name',
        category: 'Linux'
      },
      {
        title: 'Linux 系统更新',
        description: '使用 apt-get 更新系统。',
        code: 'sudo apt-get update && sudo apt-get upgrade',
        category: 'Linux'
      },
      {
        title: 'Linux 用户管理',
        description: '使用 useradd 创建新用户。',
        code: 'sudo useradd username',
        category: 'Linux'
      },
      {
        title: 'Linux 磁盘使用情况',
        description: '使用 df 命令查看磁盘使用情况。',
        code: 'df -h',
        category: 'Linux'
      },
      {
        title: 'Linux 文件查找',
        description: '使用 find 命令查找文件。',
        code: 'find /path/to/search -name "filename.txt"',
        category: 'Linux'
      },
      {
        title: 'Linux 进程终止',
        description: '使用 kill 命令终止进程。',
        code: 'kill -9 PID',
        category: 'Linux'
      },
      {
        title: 'Linux 网络连接',
        description: '使用 netstat 命令查看网络连接。',
        code: 'netstat -tuln',
        category: 'Linux'
      },
      {
        title: 'Linux 系统信息',
        description: '使用 uname 命令查看系统信息。',
        code: 'uname -a',
        category: 'Linux'
      },
      {
        title: 'Linux 用户切换',
        description: '使用 su 命令切换用户。',
        code: 'su - username',
        category: 'Linux'
      },
      {
        title: 'Linux 备份文件',
        description: '使用 tar 命令备份文件。',
        code: 'tar -czvf backup.tar.gz /path/to/directory',
        category: 'Linux'
      },
      {
        title: 'Linux 查看日志',
        description: '使用 tail 命令查看日志文件。',
        code: 'tail -f /var/log/syslog',
        category: 'Linux'
      },

      // Nginx 用例
      {
        title: 'Nginx 反向代理',
        description: '配置 Nginx 作为反向代理。',
        code: 'location / {\n    proxy_pass http://backend;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx 静态文件服务',
        description: '配置 Nginx 提供静态文件服务。',
        code: 'location /static/ {\n    root /var/www/html;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx SSL 配置',
        description: '配置 Nginx 使用 SSL。',
        code: 'server {\n    listen 443 ssl;\n    ssl_certificate /path/to/cert.pem;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx 负载均衡',
        description: '配置 Nginx 进行负载均衡。',
        code: 'upstream backend {\n    server backend1;\n    server backend2;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx URL 重写',
        description: '配置 Nginx 进行 URL 重写。',
        code: 'rewrite ^/oldpath/(.*)$ /newpath/$1 permanent;',
        category: 'Nginx'
      },
      {
        title: 'Nginx 防火墙配置',
        description: '使用 Nginx 配置防火墙规则。',
        code: 'deny 192.168.1.1;\nallow all;',
        category: 'Nginx'
      },
      {
        title: 'Nginx 反向代理缓存',
        description: '配置 Nginx 反向代理并启用缓存。',
        code: 'proxy_cache_path /tmp/cache levels=1:2 keys_zone=my_cache:10m;\nlocation / {\n    proxy_pass http://backend;\n    proxy_cache my_cache;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx 自定义错误页面',
        description: '配置 Nginx 自定义错误页面。',
        code: 'error_page 404 /404.html;\nlocation = /404.html {\n    root /usr/share/nginx/html;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx 限制请求速率',
        description: '配置 Nginx 限制请求速率。',
        code: 'limit_req_zone $binary_remote_addr zone=one:10m rate=1r/s;\nlocation / {\n    limit_req zone=one;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx 访问控制',
        description: '配置 Nginx 访问控制。',
        code: 'location / {\n    allow 192.168.1.0/24;\n    deny all;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx gzip 压缩',
        description: '配置 Nginx 启用 gzip 压缩。',
        code: 'gzip on;\ngzip_types text/plain text/css application/json application/javascript text/xml application/xml application/xml+rss text/javascript;',
        category: 'Nginx'
      },
      {
        title: 'Nginx 负载均衡算法',
        description: '配置 Nginx 负载均衡算法。',
        code: 'upstream backend {\n    least_conn;\n    server backend1;\n    server backend2;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx SSL 重定向',
        description: '配置 Nginx 将 HTTP 重定向到 HTTPS。',
        code: 'server {\n    listen 80;\n    server_name example.com;\n    return 301 https://$host$request_uri;\n}',
        category: 'Nginx'
      },
      {
        title: 'Nginx 反向代理 WebSocket',
        description: '配置 Nginx 反向代理 WebSocket。',
        code: 'location /ws {\n    proxy_pass http://backend;\n    proxy_http_version 1.1;\n    proxy_set_header Upgrade $http_upgrade;\n    proxy_set_header Connection "upgrade";\n}',
        category: 'Nginx'
      },

      // Python 用例
      {
        title: 'Python 虚拟环境',
        description: '使用 venv 创建独立的 Python 环境。',
        code: 'python -m venv myenv',
        category: 'Python'
      },
      {
        title: 'Python 安装依赖',
        description: '使用 pip 安装依赖包。',
        code: 'pip install package_name',
        category: 'Python'
      },
      {
        title: 'Python 文件读写',
        description: '使用 open 函数读取文件。',
        code: 'with open("file.txt", "r") as f:\n    content = f.read()',
        category: 'Python'
      },
      {
        title: 'Python 异常处理',
        description: '使用 try-except 处理异常。',
        code: 'try:\n    risky_code()\nexcept Exception as e:\n    print(e)',
        category: 'Python'
      },
      {
        title: 'Python 数据库连接',
        description: '使用 sqlite3 连接数据库。',
        code: 'import sqlite3\nconn = sqlite3.connect("database.db")',
        category: 'Python'
      },
      {
        title: 'Python 网络请求',
        description: '使用 requests 库发送 GET 请求。',
        code: 'import requests\nresponse = requests.get("http://example.com")',
        category: 'Python'
      },
      {
        title: 'Python 多线程',
        description: '使用 threading 模块创建多线程。',
        code: 'import threading\n\ndef worker():\n    print("Worker thread")\n\nthread = threading.Thread(target=worker)\nthread.start()',
        category: 'Python'
      },
      {
        title: 'Python 文件压缩',
        description: '使用 zipfile 模块压缩文件。',
        code: 'import zipfile\n\nwith zipfile.ZipFile("archive.zip", "w") as zipf:\n    zipf.write("file.txt")',
        category: 'Python'
      },
      {
        title: 'Python 发送邮件',
        description: '使用 smtplib 模块发送邮件。',
        code: 'import smtplib\n\nwith smtplib.SMTP("smtp.example.com") as server:\n    server.login("user", "password")\n    server.sendmail("from@example.com", "to@example.com", "Subject: Test\n\nThis is a test email.")',
        category: 'Python'
      },
      {
        title: 'Python 数据可视化',
        description: '使用 matplotlib 绘制简单图表。',
        code: 'import matplotlib.pyplot as plt\n\nplt.plot([1, 2, 3], [4, 5, 6])\nplt.show()',
        category: 'Python'
      },
      {
        title: 'Python 正则表达式',
        description: '使用 re 模块进行正则表达式匹配。',
        code: 'import re\n\npattern = r"\\d+"\ntext = "There are 123 apples"\nresult = re.findall(pattern, text)',
        category: 'Python'
      },
      {
        title: 'Python API 请求',
        description: '使用 requests 库发送 POST 请求。',
        code: 'import requests\n\nresponse = requests.post("http://example.com/api", json={"key": "value"})',
        category: 'Python'
      },
      {
        title: 'Python JSON 处理',
        description: '使用 json 模块处理 JSON 数据。',
        code: 'import json\n\ndata = {"name": "Alice", "age": 30}\njson_string = json.dumps(data)',
        category: 'Python'
      },
      {
        title: 'Python 环境变量',
        description: '使用 os 模块获取环境变量。',
        code: 'import os\n\npath = os.getenv("PATH")\nprint(path)',
        category: 'Python'
      },
    ];

    const currentCategory = ref(categories[0]);

    const filteredTechPoints = computed(() => {
      return techPoints.filter(point => point.category === currentCategory.value);
    });

    const copyToClipboard = async (code) => {
      try {
        // 方法 1: 使用 Clipboard API
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(code);
          toast.success('代码已复制到剪贴板！');
          return;
        }

        // 方法 2: 创建一个临时的 textarea 元素
        const textArea = document.createElement("textarea");
        textArea.value = code;
        document.body.appendChild(textArea);
        textArea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);

        if (successful) {
          toast.success('代码已复制到剪贴板！');
        } else {
          throw new Error('复制失败');
        }
      } catch (error) {
        console.error('复制失败:', error);
        toast.error('复制失败，请手动复制。');
      }
    };

    return {
      categories,
      currentCategory,
      filteredTechPoints,
      copyToClipboard,
    };
  }
};
</script>

<style scoped>
body {
  background-color: #f5f5f5; /* 浅灰色背景 */
  color: #333; /* 深灰色文字，提高可读性 */
}

.container {
  background-color: #f2f2f3; /* 白色容器背景 */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/*
.tabs {
  // 标签栏使用纯白色背景，与容器形成微妙的区别
  background-color: #ffffff; //浅灰色背景 
  padding: 10px;
  border-radius: 5px;
}

.sticky-notes {
  // 便签区域使用温暖的米黄色，与蓝色背景形成柔和的对比
  background-color: #fff9ec; // 米黄色背景
  padding: 20px;
  border-radius: 10px;
}

// 为了增加一些细节，我们可以为便签添加一个微妙的边框
.note {
  background-color: #ffffff; // 纯白色背景
  border-left: 4px solid #3498db; // 左边框添加蓝色强调
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.note:hover {
  background-color: #f8f8f8; // 鼠标悬停时略微改变背景色
} 
*/

h1 {
  font-size: 32px;
  color: #ec583a; /* 标题使用亮橙红色 */
  margin-bottom: 20px;
  text-align: center; /* 确保标题居中 */
}

p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
  text-align: center; /* 确保段落内容居中 */
  color: var(--secondary-color);
}

.tabs {
  background-color: #f2f2f3; /* 标签栏使用纯白色 */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  background-color: #c7d0d5; /* 标签背景色 */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px; /* 增加内边距 */
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold; /* 加粗字体 */
  color: #2c3e50; /* 标签字体颜色 */
}

.tabs button.active {
  background-color: #ff7148; /* 选中状态的背景色 */
  color: white; /* 选中状态的字体颜色 */
}

.tabs button:hover {
  background-color: #ff7148; /* 悬停状态的背景色 */
  color: white; /* 悬停状态的字体颜色 */
}

.sticky-notes {
  background-color: #f5f5f5; /* 便签区域使用浅灰色 */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.note {
  background-color: #ffffff; /* 浅灰色背景 */
  border-left: 4px solid #93b1c6; /* 左边框添加强调色 */
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
  max-width: 300px;
  position: relative;
  overflow: hidden;
  font-size: 0.65rem; /* 这将使所有文字缩小到原来的 65% */
  text-align: left; /* 确保卡片内容左对齐 */
}

.note:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* background-color: #f0f0f0; 鼠标悬停时略微改变背景色 */
}

.note h3 {
  font-size: 0.84rem; /* 原来是 1.2rem，现在约为 14px */
  margin: 0 0 10px; /* 稍微减少下边距 */
  color: #ec583a;
}

.note p {
  font-size: 0.8rem; /* 描述文字稍大一些，原来是 18px，现在约为 12px */
  line-height: 1.4;
  margin-bottom: 15px;
  text-align: left; /* 确保段落文字左对齐 */
}

.code-container {
  margin-top: 15px; /* 稍微减少上边距 */
}

.code-container button {
  font-size: 0.2rem !important; /* 设置为原始大小的 30% */
  padding: 6px 12px; /* 调整按钮大小以适应新的字体大小 */
  margin-top: 8px; /* 调整上边距 */
  background-color: #93b1c6;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: bold;
}

.code-container button:hover {
  background-color: #2980b9;
}

pre {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px; /* 减少内边距 */
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.7rem; /* 代码字体稍小，原来是 14px，现在约为 9px */
  overflow-x: auto;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: left; /* 确保代码块文字左对齐 */
}


footer {
  margin-top: 60px;
  text-align: center;
}

.beian-link {
  text-decoration: none;
  color: #93b1c6;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.beian-link:hover {
  opacity: 1;
}

.badge-subject, .badge-value {
  background-color: #c7d0d5; /* 浅灰蓝色 */
  color: #333;
  display: inline-block;
  padding: 3px 8px;
}

.badge-subject {
  background-color: #e0e0e0;
  color: #333333;
  border-radius: 3px 0 0 3px;
}

.badge-value {
  background-color: #ff7148; /* 橙色 */
  color: white;
  border-radius: 0 3px 3px 0;
}
</style>